<template>
  <main>
    <section class="firstTouch">
      <div class="firstTouch_container">
        <h1>あなただけの<br>ビューティー<br>オンライン<br>コンシェルジュ</h1>
        <img src="@/assets/about/eye-catching-drop.png">
      </div>
    </section>
    <section class="preface">
      <div class="aadComment">
        <p>”自分の肌タイプを知った上で、<br>その肌に合った商品・スキンケア方法を<br>選ぶことが重要”</p>
        <p>-米国皮膚科学会-</p>
      </div>
      <div class="preface_q1_container">
        <div class="shadow1" />
        <div class="preface_q1">
          <img src="@/assets/about/eye-catching-flower-2.png">
          <h2>あなたは自分の肌タイプを<br>知っていますか？</h2>
          <p>自分の肌タイプを知っている人は少なく、自分の肌タイプに合う化粧品・ケア方法を選ぶのはとても大変です。</p>
        </div>
      </div>
      <div class="preface_q2_container">
        <div class="shadow2" />
        <div class="preface_q2">
          <p>あなたの肌タイプは何？</p>
          <p>あなたの肌に合う商品はどれ？</p>
          <p>あなたの肌悩み、肌タイプに合うケア方法は？</p>
          <img src="@/assets/about/eye-catching-flower.png">
        </div>
      </div>
    </section>
    <section class="service_concept">
      <div class="service_concept_container">
        <div class="circle1" />
        <h1>あなただけの<br>ビューティーオンラインコンシェルジュ</h1>
      </div>
      <p class="nanikore">
        |
      </p>
      <div class="service_explanation">
        <p>
          <span class="font_BG">YUNI&nbsp;COSMEは</span><br>
          美容のプロたちの経験・知識・データに基づき1:1で接客するように、
          あなたの肌タイプに合う商品やケア方法を見つけるお手伝いをします。
        </p>
      </div>
      <p class="service_explanation_note">
        -&nbsp;
        <span>コスメブランドやスポンサーから広告費をもらわずに、中立的な立場での提案をいたします。</span>
        &nbsp;-
      </p>
      <div class="circle_container">
        <div class="circle2" />
        <div class="circle3" />
      </div>
    </section>
    <section class="cando">
      <div class="cando_container">
        <h3>~<span class="font_BG">&nbsp;YUNI&nbsp;COSME</span>でできること~</h3>
        <div class="cando_explanation_container">
          <p class="cando_explanation_title">
            ①&nbsp;皮膚科の先生による肌質診断
          </p>
          <div class="cando_explanation">
            <img src="@/assets/about/about-1.png">
            <p>
              まずは自分の肌について知ることが大事。<br>
              皮膚科の先生の肌質をチェックするための問診がチャットボットで受けることができます。
            </p>
          </div>
        </div>
        <div class="cando_explanation_container">
          <p class="cando_explanation">
            ②&nbsp;肌に合う商品をコンシェルジュに相談
          </p>
          <div class="cando_explanation">
            <img src="@/assets/about/about-2.png">
            <p>
              自分の肌に合うスキンケア商品について美容のプロであるコンシェルジュにおすすめ商品を相談することができます。<br>
              またメイクアップ商品については、肌質・仕上がり・骨格に合わせたアイテムの提案やメイク方法を教えてもらうことができます。
            </p>
          </div>
        </div>
        <div class="cando_explanation_container">
          <p class="cando_explanation">
            ③&nbsp;【COMING&nbsp;SOON】AIを使った肌に合わない成分分析
          </p>
          <div class="cando_explanation">
            <img
              src="@/assets/about/about-3.png"
              class="blur"
            >
            <p>
              自分が過去に使って良かった商品・合わなかった商品を教えていただくことで、どんな成分が自分に合わないのか、
              またその成分が入っていない商品の中でコンシェルジュおすすめ商品を知ることができます。
            </p>
          </div>
        </div>
        <div
          v-if="!isLiffOpen"
          class="guidingToLine"
        >
          <p>▼LINEに登録してサービスを開始する</p>
          <a href="https://lin.ee/meyjfHf">
            <span class="font_BG">
              YUNI&nbsp;COSME
            </span>
            を使う
            <img src="@/assets/logos/LINE_logo.svg">
          </a>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: 'About',
  props: {
    isLiffOpen : {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
main{
  font-family: "YuGothic";
  font-size: 14px;
  display: block;
}
h1{
  font-size: 24px;
  font-weight: bold;
}
h2{
  font-size: 18px;
  font-weight: bold;
}
h3{
  font-size: 20px;
  padding-bottom: 80px;
  text-align: center;
}
h3 span{
  font-weight: bold;
}
a{
  text-decoration: none;
}
.font_BG{
  font-family: "Brandon Grotesque";
}
.firstTouch{
  background-color: #ffffff;
  padding: 0 40px;
}
.firstTouch_container{
  padding: 500px 0 50px 0;
  text-align: right;
}
.firstTouch_container h1{
  font-size: 23pxß;
  display: inline-block;
  text-align: center;
  padding-bottom: 45px;
  line-height: 1.7;
}
.firstTouch_container img{
  width: 170px;
  padding: 0 15px 50px 0;
  object-fit: cover;
}

/* prefaceセクション */
.preface{
  background-color: #F5EAE7;
  padding: 190px 0 250px 0;
}
.aadComment{
  padding: 0px 40px;
  margin-bottom: 180px;
}
.aadComment p:first-child{
  line-height: 1.8;
  text-align: center;
  padding-bottom: 5px;
}
.aadComment p:last-child{
  text-align: right;
}
.preface_q1_container{
  position: relative;
  margin-bottom: 270px;
}
.preface_q1 img{
  position: absolute;
  width: 84px;
  right: -42px;
  top: -46px;
}
.preface_q1{
  background-color: #ffffff;
  padding: 30px 40px;
  margin-right: 60px;
  position: relative;
}
.preface_q1 h2{
  padding-bottom: 25px;
}
.preface_q1 p{
  line-height: 1.5;
  font-weight: bold;
}
.shadow1{
  background-color: #F29F8F;
  width: 320px;
  height: 200px;
  position: absolute;
  bottom: -60px;
  right: 0px;
}
.preface_q2_container{
  position: relative;
}
.shadow2{
  background-color: #FADAD2;
  width: 320px;
  height: 150px;
  position: absolute;
  top: -60px;
  left: 0px;
}
.preface_q2{
  background-color: #ffffff;
  padding: 30px 40px;
  margin-left: 60px;
  position: relative;
}
.preface_q2 p{
  line-height: 2;
  font-weight: bold;
}
.preface_q2 img{
  position: absolute;
  width: 90px;
  left: -45px;
}

/* service_conceptセクション */
.service_concept{
  background-color: #ffffff;
  padding: 150px 0 300px 0;
}
.service_concept_container{
  padding: 0 40px;
  position: relative;
}
.circle1{
  width: 120px;
  height: 120px;
  background-color: #FADAD2;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: -110px;
}
.service_concept_container h1{
  text-align: center;
  font-size: 20px;
}
.nanikore{
  font-size: 24px;
  text-align: center;
  padding: 24.5px 0 15px 0;
}
.service_explanation{
  padding: 15px 40px 35px 40px;
}
.service_explanation p{
  font-size: 16px;
  text-align: center;
  padding: 15px 20px;
  line-height: 1.8;
  font-weight: bold;
  background-color: #F5EAE7;
}
.service_explanation_note{
  font-size: 15px;
  font-weight: bold;
  padding: 0 40px 35px 40px;
}
.service_explanation_note span{
  text-decoration: underline;
}
.circle_container{
  position: relative;
}
.circle2{
  position: absolute;
  width: 160px;
  height: 160px;
  background-color: #FADAD2;
  border-radius: 50%;
  top: 30px;
  left: 5px;
}
.circle3{
  position: absolute;
  width: 95px;
  height: 95px;
  background-color: #F5EAE7;
  border-radius: 50px;
  top: 110px;
  left: 100px;
}

/* candoセクション */
.cando{
  background-color: #F5EAE7;
  padding: 0 30px;
}
.cando_container{
  padding: 95px 0 0 0;
}
.cando_explanation_container{
  padding-bottom: 70px;
}
.cando_explanation_container p:first-child{
  font-size: 16px;
  padding-bottom: 10px;
}
.cando_explanation{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cando_explanation img{
  width: 42%;
}
.cando_explanation p{
  line-height: 1.5;
  padding-left:10px;
}
.blur{
  filter: blur(3px);
}
.cando_explanation_container:last-child{
  padding-bottom: 0px;
}
.guidingToLine{
  padding: 70px 0 35px 0;
  text-align: center;
}
.guidingToLine p{
  font-size: 16px;
  padding-bottom: 20px;
}
.guidingToLine a{
  background-color: #F29F8F;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222222;
  font-size: 18px;
  padding: 16px 35px;
}
.guidingToLine span{
  color: #222222;
}
.guidingToLine img{
  width: 25px;
}
</style>
